import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Signup from '../components/signup'

import castual from '../images/castual.svg';

const IndexPage = () => (
  <Layout>
    <SEO title="Castual" keywords={[`Castual`, `application`, `saas`, `narrowcasting`, `casting`, `signage`, `digital signage`]} />
    <div style={{maxWidth: '1140px', textAlign: 'center', padding: '0 10px'}}>
      <div className="intro">
        <img className="logo" src={castual} />
        <h1>Castual</h1>
        <h2>Beautiful digital signage made easy.</h2>
      </div>
      <Signup />
    </div>
  </Layout>
)

export default IndexPage
