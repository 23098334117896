import React from "react"
import styles from './signup.scss';

export default (props) => {
  return (
    <div className="signup">
      <p>In the making. Fill in your email address to stay up to date.</p>
      <form action="https://castual.us20.list-manage.com/subscribe/post?u=c0c6b3dc77c666a4fa75c5b39&amp;id=e7b985b900" method="post" target="_blank">
        <div className="input">
          <input type="email" name="EMAIL" required />
          <input type="submit" value="Sign up" className="submit" />
        </div>
      </form>
    </div>
  );
}
